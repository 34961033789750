import MDinput from '@/components/MDinput';
import { validatorRequire } from '@/utils/validators';

export function getFormDefinition(vue) {
  return {
    code: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 4,
        required: true,
        textTransform: 'uppercase'
      },
      rules: [{ validator: validatorRequire }]
    },
    name: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 64,
        required: true
      },
      rules: [{ validator: validatorRequire }]
    }
  };
}

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  return item;
}
